import "./Register.css";
import React, { useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBInput,
  MDBIcon,
} from "mdb-react-ui-kit";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { registerNewUser, sendOtp } from "../../axios/services/utilityService";
import OtpRegisterModal from "../../components/OtpRegisterModal/OtpRegisterModal";
import StudentSelectModal from "../../components/StudentSelectModal/StudentSelectModal";
import { getStudentNameWithIdFromMobile } from "../../axios/services/userService";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [errors, setErrors] = useState({
    mobileNumber: "",
    email: "",
    password: "",
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [studentSelectShow, setStudentSelectShow] = useState(false);

  const navigate = useNavigate();

  const validateMobile = () => {
    let valid = true;
    const newErrors = {};

    if (!mobileNumber || mobileNumber.length < 10) {
      newErrors.mobileNumber = "Enter a valid mobile number";
      valid = false;
    }
    setErrors((prev) => ({ ...prev, ...newErrors }));
    return valid;
  };

  const validateEmail = (email) => {
    let valid = true;
    const newErrors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      newErrors.email = "Enter a valid email address";
      valid = false;
    }
    setErrors((prev) => ({ ...prev, ...newErrors }));
    return valid;
  };

  const handleOtpVerified = async () => {
    try {
      const students = await getStudentNameWithIdFromMobile(
        mobileNumber.slice(-10)
      );
      const studentsList = students.students;
      if (studentsList.length > 0) {
        setStudents(studentsList);
        setModalShow(false);
        setStudentSelectShow(true);
      } else {
        toast.error(
          "No students found with the provided mobile number. Please contact the support team.",
          {
            autoClose: 10000, // 10 seconds
          }
        );
      }
    } catch (error) {
      const sanitizedMessage = error.replace(/<\/?[^>]+(>|$)/g, ""); // Removes HTML tags
      toast.error(sanitizedMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const userRegistration = async (studentId) => {
    try {
      const newUser = await registerNewUser(
        name,
        email,
        mobileNumber,
        password,
        studentId
      );
      if (newUser.success) {
        toast.success("Registration successful");
        navigate("/");
      } else {
        toast.error("Registration failed. Please try again.");
      }
    } catch (error) {
      toast.error("Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let hasError = false;

    if (!validateMobile()) {
      toast.error("Enter a valid 10-digit mobile number");
      return;
    }

    if (!validateEmail(email)) {
      toast.error("Enter a valid email address");
      return;
    }

    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])/; // At least one number and one special character
    const newErrors = {};
    if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
      toast.error(newErrors.password);
      hasError = true;
    } else if (!passwordRegex.test(password)) {
      newErrors.password =
        "Password must include alphabets, atleast 1 special character and 1 number";
      toast.error(newErrors.password);
      hasError = true;
    } else if (password !== confirmPassword) {
      newErrors.password = "Passwords do not match";
      toast.error(newErrors.password);
      hasError = true;
    }

    setErrors((prev) => ({ ...prev, ...newErrors }));

    if (hasError) {
      return; // Stop further execution if there are errors
    }

    const response = await sendOtp(mobileNumber);
    if (response.success) {
      toast.success(`An OTP has been sent to +${mobileNumber}`);
      setOtpSent(true);
      setModalShow(true);
    }
  };

  return (
    <div className="register-page">
      <MDBContainer fluid>
        <MDBCard className="text-black m-3" style={{ borderRadius: "25px" }}>
          <MDBCardBody>
            <MDBRow>
              <MDBCol
                md="10"
                lg="6"
                className="order-2 order-lg-1 d-flex flex-column align-items-center"
              >
                <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
                  SIGN UP
                </p>

                <form onSubmit={handleSubmit} className="d-flex flex-column">
                  <div className="d-flex flex-row align-items-center mb-4 ">
                    <MDBIcon fas icon="user me-3" size="lg" />
                    <MDBInput
                      label="Your Name"
                      id="form1"
                      type="text"
                      className="w-100"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <div className="d-flex flex-row align-items-center mb-4">
                    <MDBIcon fas icon="envelope me-3" size="lg" />
                    <MDBInput
                      label="Your Email"
                      id="form2"
                      type="email"
                      className="w-100"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="d-flex flex-row align-items-center mb-4">
                    <MDBIcon fas icon="mobile-alt me-3" size="lg" />
                    <PhoneInput
                      label="Your Mobile Number"
                      id="form3"
                      country={"in"}
                      value={mobileNumber}
                      onChange={(phone) => setMobileNumber(phone)}
                      inputStyle={{ width: "100%" }}
                    />
                  </div>

                  <div className="d-flex flex-row align-items-center mb-4">
                    <MDBIcon fas icon="lock me-3" size="lg" />
                    <MDBInput
                      label="Password"
                      id="form4"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="d-flex flex-row align-items-center mb-4">
                    <MDBIcon fas icon="key me-3" size="lg" />
                    <MDBInput
                      label="Repeat your password"
                      id="form5"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>

                  <MDBBtn className="text-center mb-4" size="lg" type="submit">
                    Register
                  </MDBBtn>
                </form>
                <p>
                  Have already an account?{" "}
                  <span
                    className="link-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/")}
                  >
                    Login
                  </span>
                </p>
              </MDBCol>
              <OtpRegisterModal
                show={modalShow}
                handleClose={() => setModalShow(false)}
                mobileNumber={mobileNumber}
                onOtpVerified={handleOtpVerified}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
              <StudentSelectModal
                studentSelectShow={studentSelectShow}
                students={students}
                handleCloseStudentSelect={() => setStudentSelectShow(false)}
                email={email}
                mobileNumber={mobileNumber.slice(-10)}
                userRegistration={userRegistration}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />

              <MDBCol
                md="10"
                lg="6"
                className="order-1 order-lg-2 d-flex align-items-center"
              >
                <MDBCardImage
                  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                  fluid
                />
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </div>
  );
};

export default Register;
