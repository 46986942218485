import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { validateOTP } from "../../axios/services/utilityService";
import Spinner from "react-bootstrap/Spinner";

function OtpRegisterModal({
  show,
  handleClose,
  mobileNumber,
  onOtpVerified,
  isLoading,
  setIsLoading,
}) {
  const [otp, setOtp] = useState("");

  const validate_OTP = () => {
    let valid = true;

    if (!/^\d{4}$/.test(otp)) {
      valid = false;
    }
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!validate_OTP()) {
      toast.error("Enter a valid 4-digit OTP");
      return;
    }
    const response = await validateOTP(mobileNumber, otp);
    if (response.validated.message) {
      onOtpVerified();
    } else {
      toast.error("Invalid OTP");
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>OTP Verification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email OTP</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter the 6 digit OTP here"
                autoFocus
              />
            </Form.Group> */}
            <Form.Control
              type="text"
              placeholder="Enter the OTP here"
              value={otp}
              onChange={(e) => {
                const onlyNumbers = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                setOtp(onlyNumbers);
              }}
              maxLength={4} // Restrict input to 4 digits
              inputMode="numeric" // Shows numeric keyboard on mobile
              pattern="[0-9]*" // Ensures only numbers are entered
              autoFocus
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary" disabled={isLoading}>
              {isLoading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Proceed"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default OtpRegisterModal;
