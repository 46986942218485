import { axiosUserInstance } from "../axios";

export const getStudentFromMobile = async (mobileNumber) => {
  const { data } = await axiosUserInstance.get(
    `/get-student-from-mobile/${mobileNumber}`
  );
  if (data) return data;
};

export const getStudentNameWithIdFromMobile = async (mobileNumber) => {
  const { data } = await axiosUserInstance.get(
    `/get-studentNameWithId-from-mobile/${mobileNumber}`
  );
  if (data) return data;
};

export const getStudentFromEmail = async (email) => {
  const { data } = await axiosUserInstance.get(
    `/get-student-from-email/${email}`
  );
  if (data) return data;
};

export const getCourseList = async (studentId) => {
  const { data } = await axiosUserInstance.get(`/get-course-list/${studentId}`);
  if (data.success) {
    return data.data;
  }
};

export const getUserData = async (studentId) => {
  const { data } = await axiosUserInstance.get(`/user-profile/${studentId}`);
  return data.userDetails;
};

export const getStudentName = async (studentId) => {
  const { data } = await axiosUserInstance.get(
    `/get-student-name/${studentId}`
  );
  if (data.success) return data.studentName;
};

export const updateUserDetails = async (changes, studentId) => {
  const { data } = await axiosUserInstance.put(
    `/update-details/${studentId}`,
    changes
  );
  return data;
};

export const updateEnrollment = async (updatedDetails, enrollmentId) => {
  const { data } = await axiosUserInstance.put(
    `/update-enrollment/${enrollmentId}`,
    updatedDetails
  );
  return data;
};

export const getTransactions = async (enrollmentId) => {
  const { data } = await axiosUserInstance.get(
    `/get-transactions/${enrollmentId}`
  );
  return data;
};

export const getAttendance = async (studentId) => {
  const { data } = await axiosUserInstance.get(`/get-attendance/${studentId}`);
  return data;
};

export const getExamMarks = async (enrollmentId) => {
  const { data } = await axiosUserInstance.get(
    `/get-exam-marks/${enrollmentId}`
  );
  return data;
};

export const applyFilters = async (
  studentId,
  dateRange,
  examType,
  subjects
) => {
  const data = await axiosUserInstance.post(`/filter`, {
    studentId,
    dateRange,
    examType,
    subjects,
  });
  return data;
};

export const getSubjectsAndPaper = async () => {
  const { data } = await axiosUserInstance.get(`/get-subject&paper`);
  return data;
};

export const getExamTypes = async () => {
  const { data } = await axiosUserInstance.get(`/get-exam-types`);
  return data;
};
