import "./StudentSelectModal.css";
import { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function StudentSelectModal({
  studentSelectShow,
  students,
  handleCloseStudentSelect,
  email,
  userRegistration,
  isLoading,
  setIsLoading,
}) {
  const [selectedStudent, setSelectedStudent] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (selectedStudent) {
      userRegistration(selectedStudent);
    }
  };

  return (
    <>
      <Modal show={studentSelectShow} onHide={handleCloseStudentSelect}>
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Choose a student</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="students-list">
              <p>
                Select a student you wish to connect with <b>{email}</b>
              </p>
              {students.map((student, index) => (
                <div
                  key={index}
                  className={`student mb-3 ${
                    selectedStudent === student.name ? "selected" : ""
                  }`}
                >
                  <label htmlFor={student.name} className="ms-2">
                    <h5 className="mb-1">
                      {student.first_name} {student.last_name}
                    </h5>
                    <h6 className="mb-0">{student.name}</h6>
                  </label>
                  <input
                    type="radio"
                    id={student.name}
                    name="student"
                    value={student.name}
                    checked={selectedStudent === student.name}
                    onChange={(e) => setSelectedStudent(e.target.value)}
                  />
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary" disabled={!selectedStudent}>
              {isLoading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Proceed"
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default StudentSelectModal;
