import "./LoginWithUserId.css";
import React, { useState } from "react";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBInput,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { login } from "../../axios/services/utilityService";
import { useNavigate } from "react-router-dom";
import {
  getCourseList,
  getStudentFromEmail,
} from "../../axios/services/userService";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

function LoginWithUserId() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const setStudentId = (studentId) => {
    return localStorage.setItem("studentId", studentId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await login(email, password);
      if (response.success) {
        const { studentId } = await getStudentFromEmail(email);
        setStudentId(studentId);
        const courses = await getCourseList(studentId);
        setCourseList(courses);

        if (courses.length === 1) {
          navigate(`/course-details`, { state: { course: courses[0] } });
        } else {
          navigate(`/course-list`);
        }
      }
    } catch (error) {
      // Display the error from the backend or a generic message
      toast.error(error || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-page-container">
      <MDBContainer fluid>
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol col="12">
            <form onSubmit={handleSubmit}>
              <MDBCard
                className="bg-white my-5 mx-auto"
                style={{ borderRadius: "1rem", maxWidth: "500px" }}
              >
                <MDBCardBody className="p-5 w-100 d-flex flex-column">
                  <h2 className="fw-bold mb-2 text-center">SIGN IN</h2>
                  <p className="text-dark-10 mt-3 text-center">
                    Please enter your login ID and password!
                  </p>

                  <MDBInput
                    wrapperClass="mb-4"
                    label="Email address"
                    id="formControlLg1"
                    type="email"
                    size="lg"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <MDBInput
                    wrapperClass="mb-4"
                    label="Password"
                    id="formControlLg2"
                    type="password"
                    size="lg"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <MDBBtn size="lg" disabled={isLoading}>
                    {isLoading ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      "Login"
                    )}
                  </MDBBtn>

                  <hr className="my-4" />

                  <p className="small fw-bold mt-2 pt-1 mb-2 text-center">
                    Don't have an account?{" "}
                    <span
                      className="link-danger"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/register")}
                    >
                      Register
                    </span>
                  </p>
                </MDBCardBody>
              </MDBCard>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
export default LoginWithUserId;
